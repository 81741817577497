import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Loader from './components/Loader/Loader';
import './index.css';
import 'modern-normalize/modern-normalize.css';

const Home = lazy(() => import('./pages/Home/Home'));
const AboutUs = lazy(() => import('./pages/About/AboutPage'));
const ServicesPage = lazy(() => import('./pages/ServicesPage/ServicesPage'));
const CaseStudiesPage = lazy(() => import('./pages/CaseStudiesPage/CaseStudiesPage'));
const DmaService = lazy(() => import('./pages/DmaServicePage/DmaSevicePage'));
const CmaService = lazy(() => import('./pages/CmaServicePage/CmaSevicePage'));
const QmsService = lazy(() => import('./pages/QmsServicePage/QmsSevicePage'));
const Expertise = lazy(() => import('./pages/ExpertisePage/ExpertisePage'));
const ContactPage = lazy(() => import('./pages/ContactPage/ContactPage'));
const TabushCsCpPage = lazy(() => import('./pages/TabushCsCpPage/TabushCsCpPage'));
const EptivaCsCpPage = lazy(() => import('./pages/EptivaCsCpPage/EptivaCsCpPage'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage/ThankYouPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const CookiesPage = lazy(() => import('./pages/CookiesPage/CookiesPage'));
const TermsAndConditionsPage = lazy(() =>
  import('./pages/TermsAndConditionsPage/TermsAndConditionsPage'),
);
const ReferencesPage = lazy(() => import('./pages/ReferencesPage/ReferencesPage'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'services',
    element: <ServicesPage />,
  },
  {
    path: 'expertise',
    element: <Expertise />,
  },
  {
    path: 'aboutus',
    element: <AboutUs />,
  },
  {
    path: 'services/DMA',
    element: <DmaService />,
  },
  {
    path: 'services/CMA',
    element: <CmaService />,
  },
  {
    path: 'services/QMS',
    element: <QmsService />,
  },
  {
    path: 'contact',
    element: <ContactPage />,
  },
  {
    path: 'thankyou',
    element: <ThankYouPage />,
  },
  {
    path: 'error',
    element: <ErrorPage />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: 'cookies',
    element: <CookiesPage />,
  },
  {
    path: 'terms-and-conditions',
    element: <TermsAndConditionsPage />,
  },
  {
    path: 'references',
    element: <ReferencesPage />,
  },
  {
    path: 'test-automation-case-study',
    element: <TabushCsCpPage />,
  },
  {
    path: 'automated-network-biology-algorithm-case-study',
    element: <EptivaCsCpPage />,
  },
  {
    path: 'case-studies',
    element: <CaseStudiesPage />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);

const helmetContext = {};

const rootElement = document.getElementById('root');

const root = rootElement.hasChildNodes()
  ? ReactDOM.hydrateRoot(rootElement)
  : ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <HelmetProvider context={helmetContext}>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>,
);