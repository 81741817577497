import React from 'react';
import { ReactSVG } from 'react-svg';
import img from '../../assets/images/icons/logo.svg';
import styles from './Loader.module.css';

const Loader = () => {
  return (
    <div className={styles.parent}>
      <ReactSVG src={img} className={styles.img} />
      <div className={styles.progress}></div>
    </div>
  );
};

export default Loader;
